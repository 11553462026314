// extracted by mini-css-extract-plugin
export var checkicon = "services-module--checkicon--15bb0";
export var col4 = "services-module--col4--3d260";
export var container = "services-module--container--8603f";
export var gridRow2 = "services-module--gridRow2--5973e";
export var gridRow3 = "services-module--gridRow3--3d1f9";
export var headerDesc = "services-module--headerDesc--b8608";
export var progress = "services-module--progress--da3ba";
export var progressText = "services-module--progressText--5c1d3";
export var section = "services-module--section--ce1ba";
export var sectionDiff = "services-module--sectionDiff--4bc4c";
export var sectionHeader = "services-module--sectionHeader--e906c";
export var service = "services-module--service--ebf6e";
export var serviceDesc = "services-module--serviceDesc--1d2a6";
export var serviceHeader = "services-module--serviceHeader--2e353";
export var serviceIcon = "services-module--serviceIcon--74b9f";
export var smallHeader = "services-module--smallHeader--49aeb";